html,
body,
#root {
    margin: 0;
    height: 100%;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background: #000;
    overflow-x: hidden;
}

*,
::after,
::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

h1 {
    font-family: 'Saira Condensed', sans-serif;
    font-size: 4rem;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
    padding: 5.5rem 0;
    color: #fff;
    text-shadow: 0 0 80px #fff, 0 0 40px #fff;
}

h2 {
    font-family: 'Saira Condensed', sans-serif;
    color:#7f8bbd;
    font-size: 35px;
    line-height: 35px;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0;
    margin-bottom: 1.5rem;
}

p {
    color: #fff;
}

p,
h2 {
    text-shadow: 1px 1px 3px #000;
}

/* Safari 4.0 - 8.0 */
@keyframes scrollAnimLeft {
    0% {
        opacity: 0;
        transform: translate3d(-150rem, 0, 0);
        -webkit-transform: translate3d(-150rem, 0, 0);
        -moz-transform: translate3d(-150rem, 0, 0);
        -ms-transform: translate3d(-150rem, 0, 0);
        -o-transform: translate3d(-150rem, 0, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
    }
}

@keyframes scrollAnimRight {
    0% {
        opacity: 0;
        transform: translate3d(150rem, 0, 0);
        -webkit-transform: translate3d(150rem, 0, 0);
        -moz-transform: translate3d(150rem, 0, 0);
        -ms-transform: translate3d(150rem, 0, 0);
        -o-transform: translate3d(150rem, 0, 0);
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
    }
}

.hero {
    background: url(./candy-5d74c9b93f.jpg) no-repeat 0 0;
    height: 300px;
    background-attachment: fixed;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 85% 90%, 75% 100%, 25% 100%, 15% 90%, 0 90%);
    clip-path: polygon(0 0, 100% 0, 100% 90%, 85% 90%, 82% 100%, 18% 100%, 15% 90%, 0 90%);
    position: relative;
    z-index: 1;
}

footer {
    background: url(./buy-bar-bg-3c956a1db2.jpg) no-repeat 0 0;
    height: 150px;
    -webkit-clip-path: polygon(0% 10%, 15% 10%, 23% 0%, 82% 0%, 88% 10%, 100% 10%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 15%, 15% 15%, 18% 0%, 82% 0%, 85% 15%, 100% 15%, 100% 100%, 0% 100%);
    position: relative;
    margin-top: -2rem;
    padding: 3rem 0 2rem;

    svg {
        height: 48px;
        margin: 0 1rem;

        path {
            fill: #fff;
        }
    }
}

.text-center {
    text-align: center;
}

.content {
    max-width: 100%;
    margin: 0 2rem;
    transition: all .5s ease-in;
    -webkit-transition: all .75s ease-in;
    -moz-transition: all .75s ease-in;
    -ms-transition: all .75s ease-in;
    -o-transition: all .75s ease-in;
}

.intro {
    padding: 4rem 0;
    position: relative;
    margin-top: -2rem;
    min-height: 600px;
    z-index: 0;

    &:before {
        content : "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: url(./574521.jpg) no-repeat 50% 0;
        background-attachment: fixed;
        width: 100%;
        height: 100%;
        opacity: .25;
        z-index: -1;
    }
}

.why-tribe {
    padding: 2rem 0;
    min-height: 600px;
    position: relative;
    z-index: 0;

    &:after {
        content : "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: url(./856982.jpg) no-repeat 0 0;
        background-size: cover;
        background-attachment: fixed;
        width: 100%;
        height: 100%;
        opacity : 0.2;
        z-index: -1;
    }
}

.ow-team {
    background: url(./heroes-2560-453df94734.png) no-repeat 0 0;
    width: 320px;
    height: 175px;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: calc(50% - 160px);
}

.games-list {
    padding: 2rem 0;
    min-height: 600px;
    position: relative;
    z-index: 0;

    &:after {
        content : "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: url(./rainbow-six-beginners-guide-hero-section-background-desktop-tablet-01-en-07nov19.jpg) no-repeat 0 0;
        background-size: cover;
        background-attachment: fixed;
        width: 100%;
        height: 100%;
        opacity : 0.2;
        z-index: -1;
    }
}

.project {
    padding: 2rem 0;
    position: relative;
    min-height: 600px;
    z-index: 0;

    &:after {
        content : "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: url(./fortnite-une.jpg) no-repeat 0 0;
        background-size: cover;
        background-attachment: fixed;
        width: 100%;
        height: 100%;
        opacity : 0.2;
        z-index: -1;
    }
}

.logos-game {

    img,
    svg {
        height: 50px;
        margin: 1rem 1rem 0 0;
    }

    path {
        fill: #fff;
    }
}

@media only screen and (min-width: 40.063em) {
    .content {
        margin: 0 auto;
        max-width: 40.063em;
    }

    h2 {
        font-size: 50px;
        line-height: 50px;
    }

    .intro {
        padding: 10rem 0;
    }

    .why-tribe {
        padding: 8rem 0 225px 0;
    }

    .games-list {
        padding: 10rem 0;
    }

    .project {
        padding: 10rem 0;
    }
}

@media only screen and (min-width: 64.063em) {
    .content {
        max-width: 64.063em;
    }
}